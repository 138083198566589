<template>
<template>
  <div class="grid-container">
    <div class="header">
      <div class="flex flex-col md:flex-row justify-between items-center mb-4">
        <h1 class="text-2xl font-bold mb-4 md:mb-0">{{ $t('accounts.title') }}</h1>
      </div>
      <div class="mt-4 mb-6 border-t border-blue-200 espace"></div>
      <div class="flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 md:space-x-4 mb-4 p-4 rounded-lg" style="box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);">
        <div class="bg-blue-100 text-blue-700 px-4 py-2 rounded-lg flex items-center space-x-4 w-full md:w-auto">
          <div class="flex items-center">
            <i class="fas fa-user mr-2"></i>
            <span>{{ $t('accounts.totalAccounts', { count: totalAccounts }) }}</span>
          </div>
          <div class="flex items-center space-x-2">
            <span>🇪🇺 {{ europeanAccounts }}</span>
            <span>🇺🇸 {{ usAccounts }}</span>
          </div>
        </div>

        <button class="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-2 rounded-full flex items-center hover:from-blue-600 hover:to-blue-800"
          @click="$router.push('/checkout')">
          <i class="fas fa-plus mr-2"></i> {{ $t('accounts.getNewAccounts') }}
        </button>
      </div>
    </div>

    <div class="content">
      <div class="bg-white rounded-lg p-6 shadow-md" style="box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);">
        <div class="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4 mb-4">
          <div class="relative flex-grow">
            <span class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
              <i class="fas fa-search"></i>
            </span>
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search..."
              class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div class="relative">
            <button @click="toggleFilterDropdown" class="filter-button">
              <i class="fas fa-filter mr-2"></i> {{ selectedFilter || $t('accounts.filter') }}
              <i class="fas fa-caret-down ml-1"></i>
            </button>
            <div v-if="showFilterDropdown" class="filter-dropdown">
              <button
                v-for="option in filterOptions"
                :key="option.value"
                @click="applyFilter(option.value)"
                class="dropdown-item"
              >
                {{ option.label }}
              </button>
            </div>
          </div>

          <button @click="toggleSortOrder" class="sort-button">
            <i class="fas fa-sort mr-2"></i> {{ sortOrder === 'asc' ? $t('accounts.sortAsc') : $t('accounts.sortDesc') }}
          </button>
        </div>

        <div class="space-y-4 mt-4">
          <div
            v-for="(account, index) in displayedAccounts"
            :key="index"
            class="account-item"
          >
            <div class="flex items-center space-x-4 flex-grow">
              <img :src="account.photo" :alt="account.compte" class="w-12 h-12 rounded-full">
              <div>
                <p class="font-semibold text-lg text-gray-800">
                  {{ account.compte }}
                </p>
                <p class="text-gray-500 text-sm">{{ account.poste }} 
                  <span class="ml-2 text-lg">
                    {{ getNationality(account.pays) }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="slide-in">
      <div v-if="selectedAccount" class="details-panel">
        <h2>{{ $t('accounts.detailsTitle') }}</h2>

      </div>
    </transition>
  </div>
</template>

  </template>
  
  <script>

  export default {
    name: 'TestView',
    data() {
      return {
        username: '',
        password: '',
        errorMessage: '',
      };
    },
    methods: {
   
    },
  };
  </script>