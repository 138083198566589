<template>
  <div id="app">
    <router-view />
 </div>

</template>

<script>
export default {
  name: 'App',
  mounted() {
    console.log(this.$userback ? 'Userback est configuré' : 'Userback n’est pas configuré');
  },
};
</script>

<style>
#app{
  background-color: white;
}
</style>
