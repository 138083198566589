<template>
    <div>
      <h1>Help Page</h1>
      <p>Content for the Help page goes here.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelpView',
  };
  </script>
  