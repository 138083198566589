import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/main.css'; 
import i18n from './i18n';
import '@fortawesome/fontawesome-free/css/all.css';
import UserbackPlugin from '@userback/vue';

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(UserbackPlugin, {
    token: 'A-S7WROAV03HqXrw9j7TIzkXwDM1BwItRmtNsI6vm6N5ZxxyhQ2W',
  });

app.mount('#app');


