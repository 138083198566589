<template>
  <div  :class="['relative', (isMobile || isBig) ? 'p-[1rem] pt-[3.5rem]' : 'p-[8rem] pt-[4rem]']"  >
    <div class="header flex justify-between items-center mb-6" >
      <button class="flex items-center text-blue-500 font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
          <span class="ml-2" @click="goToPreviousStep">{{ $t('MainLayout.retour') }}</span>
        </button>
      <div 
      class="bg-white custom-border text-blue-700 px-4 py-1 flex items-center cursor-pointer custom-rounded"
      @click="toggleLanguageDropdown"
      >
        <img :src="currentFlag" alt="Langue" class="w-8 h-8 mr-2" />
        <i class="fas fa-chevron-down"></i>
      </div>

      <div 
        v-if="showLanguageDropdown" 
        class="absolute right-0 bg-white shadow-lg rounded-lg p-2 w-32"
        @click="showLanguageDropdown = false"
      >
        <div 
          class="absolute right-0 mt-2 bg-white shadow-lg rounded-lg p-2 w-32"
          @click.stop
        >
          <button
            @click="setLanguage('fr')"
            class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
          >
            <img src="../../assets/france-flag.png" alt="Français" class="w-6 h-6 mr-2" />
            Français
          </button>
          <button
            @click="setLanguage('en')"
            class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
          >
            <img src="../../assets/uk-flag.png" alt="English" class="w-6 h-6 mr-2" />
            English
          </button>
        </div>
      </div>
    </div>



      <h2 class="title-checkout text-center mb-8" :class="isMobile ? 'mt-5 ' : ''">
        {{ $t('checkout.step1Title') }}
      </h2>
      <div class="underline-gradient mx-auto mb-5"></div>

      <div class="slider-container p-6 pt-[6rem] border-2 border-dotted rounded-lg bg-white mb-8">
        <div class="relative">
          <div
            v-if="discount > 0"
            class="discount-element flex items-center px-2 py-1 rounded-full font-bold absolute bottom-9 right-4"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 22 21" fill="none" class="mr-2">
              <path d="M11.8617 2.53476L12.1182 2.96607C12.4791 3.57284 13.2308 3.81826 13.8777 3.54056L14.3375 3.34316C15.3156 2.92328 16.3856 3.7045 16.2905 4.76906L16.2458 5.26951C16.1828 5.97359 16.6473 6.61609 17.3332 6.7735L17.8206 6.8854C18.8576 7.12342 19.2663 8.38749 18.5666 9.19256L18.2377 9.57103C17.775 10.1035 17.775 10.8976 18.2377 11.4301L18.5666 11.8086C19.2663 12.6136 18.8575 13.8777 17.8206 14.1157L17.3332 14.2276C16.6473 14.3851 16.1828 15.0275 16.2458 15.7316L16.2905 16.2321C16.3856 17.2966 15.3156 18.0779 14.3375 17.658L13.8777 17.4606C13.2308 17.1829 12.4792 17.4283 12.1182 18.0351L11.8617 18.4664C11.316 19.3838 9.99335 19.3838 9.44764 18.4664L9.1911 18.0351C8.83019 17.4283 8.07856 17.1829 7.43164 17.4606L6.97182 17.658C5.99368 18.0779 4.92367 17.2966 5.01882 16.2321L5.06356 15.7316C5.12647 15.0275 4.66198 14.385 3.97616 14.2276L3.48865 14.1157C2.4517 13.8777 2.04302 12.6136 2.74269 11.8085L3.07158 11.4301C3.5343 10.8976 3.5343 10.1035 3.07158 9.57099L2.74269 9.19251C2.04302 8.38745 2.45174 7.12342 3.48865 6.88535L3.97612 6.77346C4.66194 6.616 5.12643 5.97354 5.06352 5.26947L5.01878 4.76901C4.92363 3.70446 5.99363 2.92323 6.97178 3.34312L7.4316 3.54051C8.07852 3.81822 8.8301 3.57284 9.19106 2.96603L9.4476 2.53472C9.99335 1.61733 11.3159 1.61733 11.8617 2.53476Z" fill="white"/>
              <path d="M13.9708 6.8748C13.6573 6.56127 13.1111 6.56127 12.798 6.8748L7.02616 12.6464C6.86961 12.803 6.7832 13.0111 6.7832 13.2327C6.7832 13.4541 6.86961 13.6625 7.02616 13.8192C7.18314 13.976 7.39144 14.0622 7.61267 14.0622C7.83391 14.0622 8.04199 13.976 8.19897 13.8192L13.9708 8.04761C14.294 7.72434 14.294 7.19806 13.9708 6.8748Z" fill="#FFC200"/>
              <path d="M8.09926 9.73074C8.97818 9.73074 9.6907 9.01822 9.6907 8.13929C9.6907 7.26036 8.97818 6.54785 8.09926 6.54785C7.22033 6.54785 6.50781 7.26036 6.50781 8.13929C6.50781 9.01822 7.22033 9.73074 8.09926 9.73074Z" fill="#FFC200"/>
              <path d="M12.9059 14.1643C13.7848 14.1643 14.4973 13.4518 14.4973 12.5729C14.4973 11.694 13.7848 10.9814 12.9059 10.9814C12.027 10.9814 11.3145 11.694 11.3145 12.5729C11.3145 13.4518 12.027 14.1643 12.9059 14.1643Z" fill="#FFC200"/>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="29" viewBox="0 0 2 29" fill="none">
              <path d="M0.789062 1L0.789064 28" stroke="white" stroke-width="1.03846" stroke-linecap="round" stroke-dasharray="4.15 4.15"/>
            </svg>

            <span class="mr-2 ml-2">{{ discount }}%</span>
          </div>

        </div>


        <div class="slider-container relative flex flex-col items-center">
          <div class="range-labels flex justify-between w-full absolute top-[-20px]" v-if="!isMobile">
            <span style="margin-left: 5px;">1</span>
            <span></span>
            <span>10</span>
            <span></span>
            <span>20</span>
            <span></span>
            <span>30</span>
            <span></span>
            <span>40</span>
            <span></span>
            <span>50+</span>
          </div>
          <div class="range-labels flex justify-between w-full absolute top-[-20px]" v-if="isMobile">
            <span>1</span>
            <span></span>
            <span>10</span>
            <span></span>
            <span>20</span>
            <span></span>
            <span>30</span>
            <span></span>
            <span>40</span>
            <span></span>
            <span>50+</span>
          </div>

          <input
            type="range"
            v-model="selectedAccounts"
            min="1"
            max="50"
            step="1"
            class="range-checkout w-full h-2"
            @input="updateSliderPosition(selectedAccounts)"
          />
          <div
            class="current-value absolute"
            :style="{ left: `${currentPosition}%` }"
          >
            <span
            :class="[
              'absolute top-[50%] left-[50%] translate-x-[-40%] current-value-text',
              isMobile ? 'translate-y-[-60%]' : 'translate-y-[-50%]'
            ]"
            >
              {{ selectedAccounts }}
            </span>
          </div>
        </div>


        <p class="text-center mt-10 text-selector ">
          {{ $t('checkout.selectAccounts') }}
        </p>
      </div>

      <h2 class="title-checkout text-center my-8 mt-2">
        {{ $t('checkout.step2Title') }}
      </h2>
      <div class="underline-gradient mx-auto"></div>

      <div class="flex justify-between items-center mb-6 mt-4">
        <div class="flex-grow h-[1px] bg-gray-400"></div>

        <select
          id="currency-selector"
          v-model="selectedCurrency"
          class="ml-4 p-2 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          @change="updateCurrency"
          :disabled="lockedCurrency"
        >
          <option value="eur">€ EUR </option>
          <option value="usd">$ USD </option>
        </select>
      </div>
      <div class="account-options grid grid-cols-1 md:grid-cols-2 gap-6">
        <div
          class="account-option border border-gray-300 p-6 rounded-xl  transition cursor-pointer border-blue-500 "
        >
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-card font-bold">
              {{ $t('checkout.europeanProfile') }}
            </h3>
            <img
              src="../../assets/europe.png"
              alt="Europe"
              class="w-20 h-20 inline-block"
            />
          </div>
          <div class="flex items-center space-x-4 mb-4">
            <div class="flex-input-container">
              <button
                @click="decrementValue"
                class="px-3 py-1 button-selector font-bold"
              >
                -
              </button>
              <input
                type="number"
                v-model="selectedAccounts"
                @input="updateSliderPosition"
                class="no-arrows"
                min="1"
                max="50"
              />
              <button
                @click="incrementValue"
                class="px-3 py-1 button-selector hover:bg-blue-200 font-bold"
              >
                +
              </button>
            </div>

            <div class="flex-1 text-right">
              <p class="text-price-checkout">
                <span>{{ unitPriceEU.toFixed(2) }}{{ getCurrencySymbol(selectedProduct.currency) }}</span> /profil
              </p>
            </div>
          </div>

          <div class="flex justify-between items-center">
            <div class="bg-gray-100 rounded-md px-4 py-2 container-gray">
              <p class="recap-price-checkout">
                <span>{{ $t('checkout.pricePerMonth1') }} {{ monthlyPriceEU.toFixed(2) }}{{ getCurrencySymbol(selectedProduct.currency) }}</span> {{ $t('checkout.pricePerMonth2') }}
              </p>
            </div>
            <div
            v-if="discount > 0"
            class="discount-element flex items-center px-2 py-1 rounded-full font-bold"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 22 21" fill="none" class="mr-2">
              <path d="M11.8617 2.53476L12.1182 2.96607C12.4791 3.57284 13.2308 3.81826 13.8777 3.54056L14.3375 3.34316C15.3156 2.92328 16.3856 3.7045 16.2905 4.76906L16.2458 5.26951C16.1828 5.97359 16.6473 6.61609 17.3332 6.7735L17.8206 6.8854C18.8576 7.12342 19.2663 8.38749 18.5666 9.19256L18.2377 9.57103C17.775 10.1035 17.775 10.8976 18.2377 11.4301L18.5666 11.8086C19.2663 12.6136 18.8575 13.8777 17.8206 14.1157L17.3332 14.2276C16.6473 14.3851 16.1828 15.0275 16.2458 15.7316L16.2905 16.2321C16.3856 17.2966 15.3156 18.0779 14.3375 17.658L13.8777 17.4606C13.2308 17.1829 12.4792 17.4283 12.1182 18.0351L11.8617 18.4664C11.316 19.3838 9.99335 19.3838 9.44764 18.4664L9.1911 18.0351C8.83019 17.4283 8.07856 17.1829 7.43164 17.4606L6.97182 17.658C5.99368 18.0779 4.92367 17.2966 5.01882 16.2321L5.06356 15.7316C5.12647 15.0275 4.66198 14.385 3.97616 14.2276L3.48865 14.1157C2.4517 13.8777 2.04302 12.6136 2.74269 11.8085L3.07158 11.4301C3.5343 10.8976 3.5343 10.1035 3.07158 9.57099L2.74269 9.19251C2.04302 8.38745 2.45174 7.12342 3.48865 6.88535L3.97612 6.77346C4.66194 6.616 5.12643 5.97354 5.06352 5.26947L5.01878 4.76901C4.92363 3.70446 5.99363 2.92323 6.97178 3.34312L7.4316 3.54051C8.07852 3.81822 8.8301 3.57284 9.19106 2.96603L9.4476 2.53472C9.99335 1.61733 11.3159 1.61733 11.8617 2.53476Z" fill="white"/>
              <path d="M13.9708 6.8748C13.6573 6.56127 13.1111 6.56127 12.798 6.8748L7.02616 12.6464C6.86961 12.803 6.7832 13.0111 6.7832 13.2327C6.7832 13.4541 6.86961 13.6625 7.02616 13.8192C7.18314 13.976 7.39144 14.0622 7.61267 14.0622C7.83391 14.0622 8.04199 13.976 8.19897 13.8192L13.9708 8.04761C14.294 7.72434 14.294 7.19806 13.9708 6.8748Z" fill="#FFC200"/>
              <path d="M8.09926 9.73074C8.97818 9.73074 9.6907 9.01822 9.6907 8.13929C9.6907 7.26036 8.97818 6.54785 8.09926 6.54785C7.22033 6.54785 6.50781 7.26036 6.50781 8.13929C6.50781 9.01822 7.22033 9.73074 8.09926 9.73074Z" fill="#FFC200"/>
              <path d="M12.9059 14.1643C13.7848 14.1643 14.4973 13.4518 14.4973 12.5729C14.4973 11.694 13.7848 10.9814 12.9059 10.9814C12.027 10.9814 11.3145 11.694 11.3145 12.5729C11.3145 13.4518 12.027 14.1643 12.9059 14.1643Z" fill="#FFC200"/>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="29" viewBox="0 0 2 29" fill="none">
              <path d="M0.789062 1L0.789064 28" stroke="white" stroke-width="1.03846" stroke-linecap="round" stroke-dasharray="4.15 4.15"/>
            </svg>

            <span class="mr-2 ml-2">{{ discount }}%</span>
          </div>
          </div>

          <div class="flex items-center justify-center text-info-gray mt-5">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2" width="15" height="16" viewBox="0 0 15 16" fill="none">
                <path d="M7.5 7.59169V10.7167M7.5 5.09794L7.50625 5.09107M7.5 14.1542C10.9519 14.1542 13.75 11.3561 13.75 7.90419C13.75 4.45232 10.9519 1.65419 7.5 1.65419C4.04813 1.65419 1.25 4.45232 1.25 7.90419C1.25 11.3561 4.04813 14.1542 7.5 14.1542Z" stroke="#707070" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span class="info-accounts-type">  {{ $t('checkout.prospectingEurope') }}  </span>
            </div>

          <div class="bg-gray-100 rounded-lg p-4 mt-5">
            <ul class="space-y-7">
              <li class="flex items-center">
                <svg class="checkbox-size" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.40419C5.67392 2.40419 4.40215 2.93097 3.46447 3.86866C2.52678 4.80634 2 6.07811 2 7.40419V17.4042C2 18.7303 2.52678 20.002 3.46447 20.9397C4.40215 21.8774 5.67392 22.4042 7 22.4042H17C18.3261 22.4042 19.5979 21.8774 20.5355 20.9397C21.4732 20.002 22 18.7303 22 17.4042V7.40419C22 6.07811 21.4732 4.80634 20.5355 3.86866C19.5979 2.93097 18.3261 2.40419 17 2.40419H7ZM15.73 11.0882C15.8198 10.9923 15.8899 10.8797 15.9362 10.7568C15.9825 10.6338 16.0041 10.503 15.9998 10.3717C15.9956 10.2404 15.9655 10.1112 15.9113 9.99151C15.8571 9.87184 15.7799 9.76401 15.684 9.67419C15.5881 9.58437 15.4755 9.5143 15.3526 9.468C15.2296 9.4217 15.0988 9.40007 14.9675 9.40434C14.8362 9.40861 14.707 9.4387 14.5873 9.4929C14.4677 9.54709 14.3598 9.62433 14.27 9.72019L11.187 13.0102L9.664 11.6572C9.46446 11.4913 9.20825 11.4095 8.9495 11.429C8.69075 11.4485 8.44972 11.5679 8.27732 11.7618C8.10493 11.9558 8.01467 12.2091 8.02562 12.4684C8.03657 12.7276 8.14787 12.9725 8.336 13.1512L10.586 15.1512C10.7807 15.3241 11.0352 15.4143 11.2953 15.4025C11.5554 15.3908 11.8007 15.278 11.979 15.0882L15.73 11.0882Z" fill="#1E93FD"/>
                </svg>
                <span class="text-info-accounts-spe highlight-span" v-html="$t('checkout.europeanFeature1')"></span>
              </li>
              <li class="flex items-center">
                <svg class="checkbox-size" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.40419C5.67392 2.40419 4.40215 2.93097 3.46447 3.86866C2.52678 4.80634 2 6.07811 2 7.40419V17.4042C2 18.7303 2.52678 20.002 3.46447 20.9397C4.40215 21.8774 5.67392 22.4042 7 22.4042H17C18.3261 22.4042 19.5979 21.8774 20.5355 20.9397C21.4732 20.002 22 18.7303 22 17.4042V7.40419C22 6.07811 21.4732 4.80634 20.5355 3.86866C19.5979 2.93097 18.3261 2.40419 17 2.40419H7ZM15.73 11.0882C15.8198 10.9923 15.8899 10.8797 15.9362 10.7568C15.9825 10.6338 16.0041 10.503 15.9998 10.3717C15.9956 10.2404 15.9655 10.1112 15.9113 9.99151C15.8571 9.87184 15.7799 9.76401 15.684 9.67419C15.5881 9.58437 15.4755 9.5143 15.3526 9.468C15.2296 9.4217 15.0988 9.40007 14.9675 9.40434C14.8362 9.40861 14.707 9.4387 14.5873 9.4929C14.4677 9.54709 14.3598 9.62433 14.27 9.72019L11.187 13.0102L9.664 11.6572C9.46446 11.4913 9.20825 11.4095 8.9495 11.429C8.69075 11.4485 8.44972 11.5679 8.27732 11.7618C8.10493 11.9558 8.01467 12.2091 8.02562 12.4684C8.03657 12.7276 8.14787 12.9725 8.336 13.1512L10.586 15.1512C10.7807 15.3241 11.0352 15.4143 11.2953 15.4025C11.5554 15.3908 11.8007 15.278 11.979 15.0882L15.73 11.0882Z" fill="#1E93FD"/>
                </svg>
                <span class="text-info-accounts-spe highlight-span"  v-html="$t('checkout.europeanFeature2')"></span>
              </li>
              <li class="flex items-center">
                <svg class="checkbox-size" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.40419C5.67392 2.40419 4.40215 2.93097 3.46447 3.86866C2.52678 4.80634 2 6.07811 2 7.40419V17.4042C2 18.7303 2.52678 20.002 3.46447 20.9397C4.40215 21.8774 5.67392 22.4042 7 22.4042H17C18.3261 22.4042 19.5979 21.8774 20.5355 20.9397C21.4732 20.002 22 18.7303 22 17.4042V7.40419C22 6.07811 21.4732 4.80634 20.5355 3.86866C19.5979 2.93097 18.3261 2.40419 17 2.40419H7ZM15.73 11.0882C15.8198 10.9923 15.8899 10.8797 15.9362 10.7568C15.9825 10.6338 16.0041 10.503 15.9998 10.3717C15.9956 10.2404 15.9655 10.1112 15.9113 9.99151C15.8571 9.87184 15.7799 9.76401 15.684 9.67419C15.5881 9.58437 15.4755 9.5143 15.3526 9.468C15.2296 9.4217 15.0988 9.40007 14.9675 9.40434C14.8362 9.40861 14.707 9.4387 14.5873 9.4929C14.4677 9.54709 14.3598 9.62433 14.27 9.72019L11.187 13.0102L9.664 11.6572C9.46446 11.4913 9.20825 11.4095 8.9495 11.429C8.69075 11.4485 8.44972 11.5679 8.27732 11.7618C8.10493 11.9558 8.01467 12.2091 8.02562 12.4684C8.03657 12.7276 8.14787 12.9725 8.336 13.1512L10.586 15.1512C10.7807 15.3241 11.0352 15.4143 11.2953 15.4025C11.5554 15.3908 11.8007 15.278 11.979 15.0882L15.73 11.0882Z" fill="#1E93FD"/>
                </svg>
                <span class="text-info-accounts-spe">
                  {{ $t('checkout.europeanFeature3') }}
                </span>
              </li>
              <li class="flex items-center">
                <svg class="checkbox-size" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.40419C5.67392 2.40419 4.40215 2.93097 3.46447 3.86866C2.52678 4.80634 2 6.07811 2 7.40419V17.4042C2 18.7303 2.52678 20.002 3.46447 20.9397C4.40215 21.8774 5.67392 22.4042 7 22.4042H17C18.3261 22.4042 19.5979 21.8774 20.5355 20.9397C21.4732 20.002 22 18.7303 22 17.4042V7.40419C22 6.07811 21.4732 4.80634 20.5355 3.86866C19.5979 2.93097 18.3261 2.40419 17 2.40419H7ZM15.73 11.0882C15.8198 10.9923 15.8899 10.8797 15.9362 10.7568C15.9825 10.6338 16.0041 10.503 15.9998 10.3717C15.9956 10.2404 15.9655 10.1112 15.9113 9.99151C15.8571 9.87184 15.7799 9.76401 15.684 9.67419C15.5881 9.58437 15.4755 9.5143 15.3526 9.468C15.2296 9.4217 15.0988 9.40007 14.9675 9.40434C14.8362 9.40861 14.707 9.4387 14.5873 9.4929C14.4677 9.54709 14.3598 9.62433 14.27 9.72019L11.187 13.0102L9.664 11.6572C9.46446 11.4913 9.20825 11.4095 8.9495 11.429C8.69075 11.4485 8.44972 11.5679 8.27732 11.7618C8.10493 11.9558 8.01467 12.2091 8.02562 12.4684C8.03657 12.7276 8.14787 12.9725 8.336 13.1512L10.586 15.1512C10.7807 15.3241 11.0352 15.4143 11.2953 15.4025C11.5554 15.3908 11.8007 15.278 11.979 15.0882L15.73 11.0882Z" fill="#1E93FD"/>
                </svg>
                <span class="text-info-accounts-spe">
                  {{ $t('checkout.europeanFeature4') }}
                </span>
              </li>
            </ul>
          </div>
          <div v-if="!europeanavAilability" class="bg-blue-info p-6 rounded-lg mb-6 mt-6 text-center relative max-w-3xl mx-auto">
            <p class="info-text text-lg grid grid-cols-[auto,1fr] items-center gap-2">
              <i class="fas fa-info-circle text-blue-500 mr-2"></i>
              <span>{{ $t('checkout.waitingListInfo') }} <strong>{{ $t('checkout.waitingListInfo2') }}</strong></span>
            </p>
          </div>
          <div :class="[
              'text-center button-container',
              (isMobile && europeanavAilability) || !europeanavAilability ? 'mt-5' : 'mt-auto'
            ]">
            <button
            class="button-continu text-[20px] text-white px-8 py-4 rounded-full font-semibold w-4/5 mx-auto"
            @click="proceedToPayment('european')"
                          >
              {{ $t('checkout.proceed') }}
            </button>
          </div>
        </div>
        <div
          class="account-option border border-gray-300 p-6 rounded-xl transition cursor-pointer border-blue-500"
        >
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-card font-bold">
              {{ $t('checkout.americanProfile') }}
            </h3>
            <img
              src="../../assets/america.png"
              alt="Europe"
              class="w-20 h-20 inline-block"
            />
          </div>
          <div class="flex items-center space-x-4 mb-4">
            <div class="flex-input-container">
              <button
                @click="decrementValue"
                class="px-3 py-1 button-selector font-bold"
              >
                -
              </button>
              <input
                type="number"
                v-model="selectedAccounts"
                @input="updateSliderPosition"
                class="no-arrows"
                min="1"
                max="50"
              />
              <button
                @click="incrementValue"
                class="px-3 py-1 button-selector hover:bg-blue-200 font-bold"
              >
                +
              </button>
            </div>
            <div class="flex-1 text-right">
                <p class="text-price-checkout">
                <span>{{ unitPriceUSA.toFixed(2) }}{{ getCurrencySymbol(selectedProduct.currency) }}</span> /profil
              </p>
            </div>
          </div>

          <div class="flex justify-between items-center">
            <div class="bg-gray-100 rounded-md px-4 py-2 container-gray">
              <p class="recap-price-checkout">
                <span>{{ $t('checkout.pricePerMonth1') }} {{ monthlyPriceUSA.toFixed(2) }}{{ getCurrencySymbol(selectedProduct.currency) }}</span> {{ $t('checkout.pricePerMonth2') }}
              </p>
            </div>

            <div
            v-if="discount > 0"
            class="discount-element flex items-center px-2 py-1 rounded-full font-bold"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 22 21" fill="none" class="mr-2">
              <path d="M11.8617 2.53476L12.1182 2.96607C12.4791 3.57284 13.2308 3.81826 13.8777 3.54056L14.3375 3.34316C15.3156 2.92328 16.3856 3.7045 16.2905 4.76906L16.2458 5.26951C16.1828 5.97359 16.6473 6.61609 17.3332 6.7735L17.8206 6.8854C18.8576 7.12342 19.2663 8.38749 18.5666 9.19256L18.2377 9.57103C17.775 10.1035 17.775 10.8976 18.2377 11.4301L18.5666 11.8086C19.2663 12.6136 18.8575 13.8777 17.8206 14.1157L17.3332 14.2276C16.6473 14.3851 16.1828 15.0275 16.2458 15.7316L16.2905 16.2321C16.3856 17.2966 15.3156 18.0779 14.3375 17.658L13.8777 17.4606C13.2308 17.1829 12.4792 17.4283 12.1182 18.0351L11.8617 18.4664C11.316 19.3838 9.99335 19.3838 9.44764 18.4664L9.1911 18.0351C8.83019 17.4283 8.07856 17.1829 7.43164 17.4606L6.97182 17.658C5.99368 18.0779 4.92367 17.2966 5.01882 16.2321L5.06356 15.7316C5.12647 15.0275 4.66198 14.385 3.97616 14.2276L3.48865 14.1157C2.4517 13.8777 2.04302 12.6136 2.74269 11.8085L3.07158 11.4301C3.5343 10.8976 3.5343 10.1035 3.07158 9.57099L2.74269 9.19251C2.04302 8.38745 2.45174 7.12342 3.48865 6.88535L3.97612 6.77346C4.66194 6.616 5.12643 5.97354 5.06352 5.26947L5.01878 4.76901C4.92363 3.70446 5.99363 2.92323 6.97178 3.34312L7.4316 3.54051C8.07852 3.81822 8.8301 3.57284 9.19106 2.96603L9.4476 2.53472C9.99335 1.61733 11.3159 1.61733 11.8617 2.53476Z" fill="white"/>
              <path d="M13.9708 6.8748C13.6573 6.56127 13.1111 6.56127 12.798 6.8748L7.02616 12.6464C6.86961 12.803 6.7832 13.0111 6.7832 13.2327C6.7832 13.4541 6.86961 13.6625 7.02616 13.8192C7.18314 13.976 7.39144 14.0622 7.61267 14.0622C7.83391 14.0622 8.04199 13.976 8.19897 13.8192L13.9708 8.04761C14.294 7.72434 14.294 7.19806 13.9708 6.8748Z" fill="#FFC200"/>
              <path d="M8.09926 9.73074C8.97818 9.73074 9.6907 9.01822 9.6907 8.13929C9.6907 7.26036 8.97818 6.54785 8.09926 6.54785C7.22033 6.54785 6.50781 7.26036 6.50781 8.13929C6.50781 9.01822 7.22033 9.73074 8.09926 9.73074Z" fill="#FFC200"/>
              <path d="M12.9059 14.1643C13.7848 14.1643 14.4973 13.4518 14.4973 12.5729C14.4973 11.694 13.7848 10.9814 12.9059 10.9814C12.027 10.9814 11.3145 11.694 11.3145 12.5729C11.3145 13.4518 12.027 14.1643 12.9059 14.1643Z" fill="#FFC200"/>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="29" viewBox="0 0 2 29" fill="none">
              <path d="M0.789062 1L0.789064 28" stroke="white" stroke-width="1.03846" stroke-linecap="round" stroke-dasharray="4.15 4.15"/>
            </svg>

            <span class="mr-2 ml-2">{{ discount }}%</span>
          </div>
          </div>
          <div class="flex items-center justify-center text-info-gray mt-5">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2" width="15" height="16" viewBox="0 0 15 16" fill="none">
                <path d="M7.5 7.59169V10.7167M7.5 5.09794L7.50625 5.09107M7.5 14.1542C10.9519 14.1542 13.75 11.3561 13.75 7.90419C13.75 4.45232 10.9519 1.65419 7.5 1.65419C4.04813 1.65419 1.25 4.45232 1.25 7.90419C1.25 11.3561 4.04813 14.1542 7.5 14.1542Z" stroke="#707070" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span class="info-accounts-type">{{ $t('checkout.prospectingUS') }}</span>
            </div>

          <div class="bg-gray-100 rounded-lg p-4 mt-5">
            <ul class="space-y-7">
              <li class="flex items-center">
                <svg class="checkbox-size" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.40419C5.67392 2.40419 4.40215 2.93097 3.46447 3.86866C2.52678 4.80634 2 6.07811 2 7.40419V17.4042C2 18.7303 2.52678 20.002 3.46447 20.9397C4.40215 21.8774 5.67392 22.4042 7 22.4042H17C18.3261 22.4042 19.5979 21.8774 20.5355 20.9397C21.4732 20.002 22 18.7303 22 17.4042V7.40419C22 6.07811 21.4732 4.80634 20.5355 3.86866C19.5979 2.93097 18.3261 2.40419 17 2.40419H7ZM15.73 11.0882C15.8198 10.9923 15.8899 10.8797 15.9362 10.7568C15.9825 10.6338 16.0041 10.503 15.9998 10.3717C15.9956 10.2404 15.9655 10.1112 15.9113 9.99151C15.8571 9.87184 15.7799 9.76401 15.684 9.67419C15.5881 9.58437 15.4755 9.5143 15.3526 9.468C15.2296 9.4217 15.0988 9.40007 14.9675 9.40434C14.8362 9.40861 14.707 9.4387 14.5873 9.4929C14.4677 9.54709 14.3598 9.62433 14.27 9.72019L11.187 13.0102L9.664 11.6572C9.46446 11.4913 9.20825 11.4095 8.9495 11.429C8.69075 11.4485 8.44972 11.5679 8.27732 11.7618C8.10493 11.9558 8.01467 12.2091 8.02562 12.4684C8.03657 12.7276 8.14787 12.9725 8.336 13.1512L10.586 15.1512C10.7807 15.3241 11.0352 15.4143 11.2953 15.4025C11.5554 15.3908 11.8007 15.278 11.979 15.0882L15.73 11.0882Z" fill="#1E93FD"/>
                </svg>
                <span class="text-info-accounts-spe highlight-span" v-html="$t('checkout.americanFeature1')">
                </span>
              </li>
              <li class="flex items-center">
                <svg class="checkbox-size" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.40419C5.67392 2.40419 4.40215 2.93097 3.46447 3.86866C2.52678 4.80634 2 6.07811 2 7.40419V17.4042C2 18.7303 2.52678 20.002 3.46447 20.9397C4.40215 21.8774 5.67392 22.4042 7 22.4042H17C18.3261 22.4042 19.5979 21.8774 20.5355 20.9397C21.4732 20.002 22 18.7303 22 17.4042V7.40419C22 6.07811 21.4732 4.80634 20.5355 3.86866C19.5979 2.93097 18.3261 2.40419 17 2.40419H7ZM15.73 11.0882C15.8198 10.9923 15.8899 10.8797 15.9362 10.7568C15.9825 10.6338 16.0041 10.503 15.9998 10.3717C15.9956 10.2404 15.9655 10.1112 15.9113 9.99151C15.8571 9.87184 15.7799 9.76401 15.684 9.67419C15.5881 9.58437 15.4755 9.5143 15.3526 9.468C15.2296 9.4217 15.0988 9.40007 14.9675 9.40434C14.8362 9.40861 14.707 9.4387 14.5873 9.4929C14.4677 9.54709 14.3598 9.62433 14.27 9.72019L11.187 13.0102L9.664 11.6572C9.46446 11.4913 9.20825 11.4095 8.9495 11.429C8.69075 11.4485 8.44972 11.5679 8.27732 11.7618C8.10493 11.9558 8.01467 12.2091 8.02562 12.4684C8.03657 12.7276 8.14787 12.9725 8.336 13.1512L10.586 15.1512C10.7807 15.3241 11.0352 15.4143 11.2953 15.4025C11.5554 15.3908 11.8007 15.278 11.979 15.0882L15.73 11.0882Z" fill="#1E93FD"/>
                </svg>
                <span class="text-info-accounts-spe highlight-span"  v-html="$t('checkout.americanFeature2')">
                </span>
              </li>
              <li class="flex items-center">
                <svg class="checkbox-size" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.40419C5.67392 2.40419 4.40215 2.93097 3.46447 3.86866C2.52678 4.80634 2 6.07811 2 7.40419V17.4042C2 18.7303 2.52678 20.002 3.46447 20.9397C4.40215 21.8774 5.67392 22.4042 7 22.4042H17C18.3261 22.4042 19.5979 21.8774 20.5355 20.9397C21.4732 20.002 22 18.7303 22 17.4042V7.40419C22 6.07811 21.4732 4.80634 20.5355 3.86866C19.5979 2.93097 18.3261 2.40419 17 2.40419H7ZM15.73 11.0882C15.8198 10.9923 15.8899 10.8797 15.9362 10.7568C15.9825 10.6338 16.0041 10.503 15.9998 10.3717C15.9956 10.2404 15.9655 10.1112 15.9113 9.99151C15.8571 9.87184 15.7799 9.76401 15.684 9.67419C15.5881 9.58437 15.4755 9.5143 15.3526 9.468C15.2296 9.4217 15.0988 9.40007 14.9675 9.40434C14.8362 9.40861 14.707 9.4387 14.5873 9.4929C14.4677 9.54709 14.3598 9.62433 14.27 9.72019L11.187 13.0102L9.664 11.6572C9.46446 11.4913 9.20825 11.4095 8.9495 11.429C8.69075 11.4485 8.44972 11.5679 8.27732 11.7618C8.10493 11.9558 8.01467 12.2091 8.02562 12.4684C8.03657 12.7276 8.14787 12.9725 8.336 13.1512L10.586 15.1512C10.7807 15.3241 11.0352 15.4143 11.2953 15.4025C11.5554 15.3908 11.8007 15.278 11.979 15.0882L15.73 11.0882Z" fill="#1E93FD"/>
                </svg>
                <span class="text-info-accounts-spe">
                  {{ $t('checkout.americanFeature3') }}
                </span>
              </li>
              <li class="flex items-center">
                <svg class="checkbox-size" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.40419C5.67392 2.40419 4.40215 2.93097 3.46447 3.86866C2.52678 4.80634 2 6.07811 2 7.40419V17.4042C2 18.7303 2.52678 20.002 3.46447 20.9397C4.40215 21.8774 5.67392 22.4042 7 22.4042H17C18.3261 22.4042 19.5979 21.8774 20.5355 20.9397C21.4732 20.002 22 18.7303 22 17.4042V7.40419C22 6.07811 21.4732 4.80634 20.5355 3.86866C19.5979 2.93097 18.3261 2.40419 17 2.40419H7ZM15.73 11.0882C15.8198 10.9923 15.8899 10.8797 15.9362 10.7568C15.9825 10.6338 16.0041 10.503 15.9998 10.3717C15.9956 10.2404 15.9655 10.1112 15.9113 9.99151C15.8571 9.87184 15.7799 9.76401 15.684 9.67419C15.5881 9.58437 15.4755 9.5143 15.3526 9.468C15.2296 9.4217 15.0988 9.40007 14.9675 9.40434C14.8362 9.40861 14.707 9.4387 14.5873 9.4929C14.4677 9.54709 14.3598 9.62433 14.27 9.72019L11.187 13.0102L9.664 11.6572C9.46446 11.4913 9.20825 11.4095 8.9495 11.429C8.69075 11.4485 8.44972 11.5679 8.27732 11.7618C8.10493 11.9558 8.01467 12.2091 8.02562 12.4684C8.03657 12.7276 8.14787 12.9725 8.336 13.1512L10.586 15.1512C10.7807 15.3241 11.0352 15.4143 11.2953 15.4025C11.5554 15.3908 11.8007 15.278 11.979 15.0882L15.73 11.0882Z" fill="#1E93FD"/>
                </svg>
                <span class="text-info-accounts-spe">
                  {{ $t('checkout.americanFeature4') }}
                </span>
              </li>
            </ul>
          </div>
          <div v-if="!americanAvailability" class="bg-blue-info p-6 rounded-lg mb-6 mt-6 text-center relative max-w-3xl mx-auto">
          
            <p class="info-text text-lg flex items-center grid grid-cols-[auto,1fr] items-center gap-2">
              <i class="fas fa-info-circle text-blue-500 mr-2"></i>
              <span>{{ $t('checkout.waitingListInfo') }} <strong>{{ $t('checkout.waitingListInfo2') }}</strong></span>
            </p>
          </div>
          <div :class="[
              'text-center button-container',
              (isMobile && americanAvailability) || !americanAvailability ? 'mt-5' : 'mt-auto'
            ]">
            <button
            class="button-continu text-[20px] text-white px-8 py-4 rounded-full font-semibold w-4/5 mx-auto"
            @click="proceedToPayment('american')"
              >
              {{ $t('checkout.proceed') }}
            </button>
          </div>
        </div>
        
      </div>

      
    </div>
</template>

<script>
import franceFlag from '../../assets/france-flag.png';
import ukFlag from '../../assets/uk-flag.png';
import axios from '../../plugins/axios';

export default {
  name: 'CheckoutView',
  data() {
    return {
      selectedAccounts: 10,
      currentPosition: 19.5,
      selectedAccountType: 'european',
      selectedCurrency: 'eur',
      lockedCurrency: false,
      showLanguageDropdown: false,
      currentLanguage: localStorage.getItem('langue'),
      flags: {
        fr: franceFlag,
        en: ukFlag,
      },
      products: [],
      americanProduct: null,
      europeanProduct: null,
      discount: 0,
      monthlyPriceUSA: 0,
      unitPriceUSA: 0,
      monthlyPriceEU: 0,
      unitPriceEU: 0,
      isLoading: true,
      europeanavAilability: true,
      americanAvailability: true,
      isMobile: false,
      isBig: false,
    };
  },

  computed: {
    currentFlag() {
      const languageKey = this.currentLanguage.split('-')[0];
      console.log("languageKey");
      console.log(languageKey);
      return this.flags[languageKey] || this.flags.en;
    },
    selectedProduct() {
      const currency = this.selectedCurrency ? this.selectedCurrency.toUpperCase() : '';
      let unitPrice = 0;
      let totalPrice = 0;

      if (this.selectedAccountType === 'european' && this.europeanProduct) {
        unitPrice = this.unitPriceEU;
        totalPrice = this.monthlyPriceEU;
      } else if (this.selectedAccountType === 'american' && this.americanProduct) {
        unitPrice = this.unitPriceUSA;
        totalPrice = this.monthlyPriceUSA;
      } else {
        console.warn('Selected product is not available.');
      }

      return {
        unitPrice,
        totalPrice,
        currency,
      };
    },  
  },

  watch: {
    selectedAccounts: 'calculatePrices',
  },

  methods: {
    incrementValue() {
      this.updateSliderPosition(Number(this.selectedAccounts) + 1);
    },
    decrementValue() {
      this.updateSliderPosition(Number(this.selectedAccounts) - 1);
    },

    updateSliderStyle() {
      const range = document.querySelector('.range-checkout');
      
      if (!range) {
        console.error('Slider element not found!');
        return;
      }

      const min = parseInt(range.min);
      const max = parseInt(range.max);
      const value = this.selectedAccounts; 

      const percentage = ((value - min) / (max - min)) * 100;

      range.style.setProperty('--slider-value', `'${value}'`); 
      range.style.setProperty('--slider-percentage', `${percentage}%`); 
    },
    setLanguage(lang) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
      this.showLanguageDropdown = false;
      this.styleNestedSpans();
    },
    styleNestedSpans() {
      const elements = document.querySelectorAll('.highlight-span');
      elements.forEach((element) => {
        const nestedSpan = element.querySelector('span');
        if (nestedSpan) {
          nestedSpan.style.color = '#1E93FD';
          nestedSpan.style.fontWeight = '700';
        }
      });
    },
    toggleLanguageDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    updateIsMobile() {
        this.isMobile = window.innerWidth <= 900; 
        this.isBig = window.innerWidth <= 1700; 
        console.log(this.isMobile);

      },
    getCurrencySymbol(currency) {
      if (!currency) return '';
      const symbols = {
        eur: '€',
        usd: '$',
      };
      return symbols[currency.toLowerCase()] || currency.toUpperCase();
    },
    updateSliderPosition(input) {
      const range = document.querySelector('.range-checkout');
      const min = parseInt(range.min);
      const max = parseInt(range.max);
      let minPercentage = 2.8; 
      let maxPercentage = 96; 
      if(this.isMobile){
        minPercentage = 5; 
        maxPercentage = 91; 
      }
      

      let value;

      if (input && input.target) {
        value = parseInt(input.target.value);
      } else if (typeof input === 'number') {
        value = Math.min(max, Math.max(min, input)); 
      } else {
        value = this.selectedAccounts;
      }

      const percentage = (value - min) / (max - min);

      const adjustedPercentage =
        minPercentage + percentage * (maxPercentage - minPercentage);

      this.selectedAccounts = value;
      this.currentPosition = adjustedPercentage; 
      console.log({ value, percentage, adjustedPercentage });
      range.value = value;

      this.$forceUpdate();
    },


    goToPreviousStep() {
       this.$router.push('/accounts');
     },



    updateCurrency() {
      if (!this.lockedCurrency) {
        this.calculatePrices();
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get('/api/products-prices?type=main');
        const products = response.data.products;

        if (!products || !Array.isArray(products)) {
          console.error('Invalid products data:', products);
          return;
        }

        if (localStorage.getItem('customerCurrency')) {
          this.selectedCurrency = localStorage.getItem('customerCurrency').toLowerCase();
          this.lockedCurrency = true;
        } else {
          this.lockedCurrency = false;
        }

        this.americanProduct = null;
        this.europeanProduct = null;

        const currencyMap = {
          eur: 'eu',
          usd: 'us',
        };
        const currencyLabel = currencyMap[this.selectedCurrency];

        products.forEach(product => {
          if (!product.prices) {
            console.error('Product has no prices:', product);
            return;
          }
          const priceLabel = product.prices.price_label.toLowerCase();

          console.log('Product:', product);
          console.log('Product Name US:', product.nameUS);
          console.log('Product Name EU:', product.nameEU);
          console.log('Price Label:', priceLabel);
          console.log('Currency Label:', currencyLabel);

          if (
            (product.nameUS === 'American Profile' || product.nameEU === 'Profil Américain') &&
            priceLabel === currencyLabel
          ) {
            this.americanProduct = product;
            console.log('Assigned to americanProduct:', product);
          } else if (
            (product.nameUS === 'European Profile' || product.nameEU === 'Profil Européen') &&
            priceLabel === currencyLabel
          ) {
            this.europeanProduct = product;
            console.log('Assigned to europeanProduct:', product);
          }
        });
        this.americanAvailability = this.americanProduct.prices.availability;
        this.europeanavAilability = this.europeanProduct.prices.availability;

        console.log('Final americanProduct:', this.americanProduct);
        console.log('Final europeanProduct:', this.europeanProduct);

        this.calculatePrices();
        localStorage.setItem('paymentDataFull', null);
        localStorage.setItem('paymentData', null);
        localStorage.setItem('currentStep', 1);
        const Event = new StorageEvent('storage', {
          key: 'paymentData',
          newValue: null,
        });
        const ssEvent = new StorageEvent('storage', {
          key: 'paymentDataFull',
          newValue: null,
        });
        const stepEvent = new StorageEvent('storage', {
          key: 'currentStep',
          newValue: 1,
        });
        window.dispatchEvent(stepEvent);
        window.dispatchEvent(ssEvent);
        window.dispatchEvent(Event);

        this.isLoading = false;
      } catch (error) {
        console.error('Erreur lors de la récupération des produits:', error);
      }
    },

    calculatePrices() {
      if (this.selectedAccounts >= 5) {
        this.discount = Math.min(Math.floor((this.selectedAccounts) / 5) * 5, 35);
      } else {
        this.discount = 0;
      }

      if (this.americanProduct) {
        const americanPrice = this.americanProduct.prices;
        if (americanPrice) {
          const { totalPrice: totalPriceUSA, currentUnitPrice: unitPriceUSA } = this.calculateTotalPrice(
            americanPrice.tier_quantity || [],
            this.selectedAccounts
          );
          this.monthlyPriceUSA = totalPriceUSA ;
          this.unitPriceUSA = unitPriceUSA;
        }
      }

      if (this.europeanProduct) {
        const europeanPrice = this.europeanProduct.prices;
        if (europeanPrice) {
          const { totalPrice: totalPriceEU, currentUnitPrice: unitPriceEU } = this.calculateTotalPrice(
            europeanPrice.tier_quantity || [],
            this.selectedAccounts
          );
          this.monthlyPriceEU = totalPriceEU;
          this.unitPriceEU = unitPriceEU;
        }
      }
    },
    calculateTotalPrice(tiers, quantity) {
      if (!tiers || !Array.isArray(tiers) || tiers.length === 0 || !quantity) {
        return { totalPrice: 0, currentUnitPrice: 0 };
      }
      let totalPrice = 0;
      let remainingQuantity = quantity;
      let currentUnitPrice = 0;

      for (let i = 0; i < tiers.length; i++) {
        const tier = tiers[i];
        const previousTierUpTo = i === 0 ? 0 : tiers[i - 1].up_to || 0;

        const applicableQuantity = tier.up_to
          ? Math.min(remainingQuantity, tier.up_to - previousTierUpTo)
          : remainingQuantity;

        if (applicableQuantity > 0) {
          currentUnitPrice = tier.unit_amount || 0;
        }

        remainingQuantity -= applicableQuantity;

        if (remainingQuantity <= 0) break;
      }

      totalPrice = quantity * currentUnitPrice;
      return { totalPrice, currentUnitPrice };
    },

    proceedToPayment(accountType) {
      this.selectedAccountType = accountType;
      if (!accountType) {
        alert(this.$t('checkout.selectAccountTypeError'));
        return;
      }

      const selectedProduct =
        accountType === 'american'
          ? this.americanProduct
          : this.europeanProduct;

      if (!selectedProduct) {
        alert('Aucun produit trouvé pour le type sélectionné.');
        return;
      }

      if (!this.selectedProduct) {
        alert('Selected product is not available.');
        return;
      }

      const paymentData = {
        type: accountType,
        quantity: this.selectedAccounts,
        unitPrice: this.selectedProduct.unitPrice,
        totalPrice: this.selectedProduct.totalPrice,
        currency: this.selectedCurrency ? this.selectedCurrency.toUpperCase() : '',
      };

      console.log('Données enregistrées dans localStorage :', paymentData);
      const currentStep = 2;

      localStorage.setItem('paymentData', JSON.stringify(paymentData));
      localStorage.setItem('currentStep', currentStep);
      const event = new StorageEvent('storage', {
        key: 'paymentData',
        newValue: JSON.stringify(paymentData),
      });
      const stepEvent = new StorageEvent('storage', {
        key: 'currentStep',
        newValue: currentStep.toString(),
      });

      window.dispatchEvent(event);
      window.dispatchEvent(stepEvent);

      this.$router.push('/checkout/v2');
    },
  },

  mounted() {
    this.fetchProducts();
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile);
    this.styleNestedSpans();
    this.updateSliderStyle();
    this.updateSliderPosition(10);
  },
};
</script>




<style scoped>
.dropdown-transition {
  transition: all 0.3s ease-in-out;
}

.info-text {
  color: #3862FF;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.info-text i {
  font-size: 18px;
}

.bg-blue-info {
  background: rgba(123, 191, 253, 0.16);
}

.title-checkout {
  color: #000;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.underline-gradient {
  width: 100px;
  height: 4px;
  border-radius: 5.5px;
  background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
  margin-top: -20px;
}

.slider-container {
  position: relative;
  width: 100%;
}

.range-checkout {
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
  border-radius: 20px;
  outline: none;
}

.range-checkout::-webkit-slider-runnable-track {
  height: 35px;
  background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
  border-radius: 20px;
}

.range-checkout::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3153C7 100%));
  border: 10px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 7px 8px rgba(0, 0, 0, 0.1),
              0 0 0 2px var(--gradient, #1E93FD);
  position: relative;
  bottom: 10px;
  right: 5px;
}

.range-checkout::-webkit-slider-thumb::after {
  content: var(--slider-value, '50'); 
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #1e93fd;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}



.current-value {
  position: absolute;
  top: 5px;
  transform: translateX(-50%);
  pointer-events: none;
}

.range-labels {
  position: absolute;
  top: -10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #FFF;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.discount-element {
  border-radius: 6.231px;
  background: var(--gold, linear-gradient(147deg, #FFE878 -25.73%, #FEAD00 100%));
  color: #FFF;
  font-family: Montserrat;
  font-size: 18.692px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.text-card {
  color: #000;
  font-family: Montserrat;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.button-container {
  display: flex;
  justify-content: center;
}


.text-selector{
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 140%; 
background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3153C7 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.current-value-text{
color: #FFAF19;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 140%; 
}

.account-option {
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%; 
}

.account-options {
align-items: stretch; 
}


#currency-selector{
border-radius: 62px;
border: 1px solid var(--gradient, #1E93FD);
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 140%;
background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3153C7 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

#currency-selector span{
color: #000;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 127.5%;
}

.custom-border{
border-radius: 62px;
border: 1px solid var(--gradient, #1E93FD);
}

.button-selector {
background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
color: white;
padding: 0.5rem 1rem;
font-size: 17px; 
}


.info-accounts-type{
color: #707070;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 15px;
margin-bottom: 15px;

}


.flex-input-container {
display: flex;
align-items: center;
flex-grow: 0;
  flex-shrink: 0;
border: 1px solid #1E93FD;
border-radius: 10px;
overflow: hidden;

}


.flex-input-container input {
width: 50px; 
text-align: center;
border-left: 1px solid #d1d5db;
border-right: 1px solid #d1d5db;
outline: none;
font-size: 14px;
padding: 0.5rem;
}

.text-info-accounts-spe{
margin-left:5px;
color: #000;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 127.5%;
}

.text-info-accounts-spe .highlight {
color: #1E93FD;
font-weight: 700;
}

.no-arrows{
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}
.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

.no-arrows {
-moz-appearance: textfield;
}


.range-value {
position: absolute;
top: -25px;
transform: translateX(-50%);
background-color: white;
border: 1px solid #1E93FD;
border-radius: 4px;
padding: 2px 4px;
font-size: 12px;
color: #1E93FD;
font-weight: bold;
z-index: 3;
white-space: nowrap;
pointer-events: none;
}



.text-info-gray{
color: #707070;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.button-continu {
  background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%);
  box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);
  transition: background 1s ease, box-shadow 1s ease; 
}

.button-continu:hover {
  background: linear-gradient(90deg, #3154C8 0%, #3154C8 100%);
  box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);
}


.text-price-checkout span{
  color: #000;
  font-family: Montserrat;
  font-size: 25.172px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}

.recap-price-checkout span{
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 14.188px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; 
}


/* --- Media Queries pour Tablette --- */
@media (min-width: 768px) and (max-width: 1024px) {
  .title-checkout {
    font-size: 26px;
  }

  .text-selector{
    font-size: 16px;
  }

  .underline-gradient {
    width: 120px;
    height: 5px;
  }

  .range-checkout::-webkit-slider-runnable-track {
    height: 40px;
  }

  .range-checkout::-webkit-slider-thumb {
    width: 35px;
    height: 35px;
  }

  .range-labels {
    font-size: 18px;
  }

  .discount-element {
    font-size: 20px;
  }

  .text-card {
    font-size: 2rem;
  }

  .text-price-checkout {
    font-size: 18px;
  }

  .text-price-checkout span {
    font-size: 27px;
  }

  .recap-price-checkout {
    font-size: 13px;
  }

  .recap-price-checkout span {
    font-size: 15px;
  }
}



@media (max-width: 768px) {
.current-value-text{
  font-size: 14px;
}
}
/* Mode Laptop */
@media (min-width: 769px) {
.flex-input-container input {
  width: 60px;
  font-size: 16px;
}
.current-value-text{
  font-size: 18px;
}
.info-accounts-type{
  font-size: 15px;
  font-weight: 400;
}
.button-selector {
  font-size: 20px;
  padding: 0.6rem 1.2rem;
}
.text-info-accounts-spe{
  font-size: 15px;
}

.info-accounts-type{
  font-size: 12px;
}

.text-card{
  font-size: 1.5rem;
}

.text-price-checkout{
  font-size:16px;

}

.text-price-checkout span{
  font-size:20px;
}


.container-gray{
  margin-right: 15px;
}

.recap-price-checkout span{
  font-size: 12px;
}

.discount-element{
  font-size: 16px;
}

.discount-element svg{
  height: 20px;
  margin-right: 0px;
}

.checkbox-size{
  width: 2vw;
}

.range-checkout::-webkit-slider-runnable-track {
    height: 45px;
  }

  .range-checkout::-webkit-slider-thumb {
    width: 65px;
    height: 65px;
  }


}

/* Mode Desktop */
@media (min-width: 1025px) {

.flex-input-container input {
  width: 70px;
  font-size: 24px;
}

.info-accounts-type{
  font-size: 15px;
  font-weight: 400;
}
.button-selector {
  font-size: 25px;
}


.title-checkout {
    font-size: 26px;
  }

  .text-selector{
    font-size: 18px;
  }

  .underline-gradient {
    width: 150px;
    height: 6px;
  }

  .range-checkout::-webkit-slider-runnable-track {
    height: 45px;
  }

  .range-checkout::-webkit-slider-thumb {
    width: 65px;
    height: 65px;
  }

  .range-labels {
    font-size: 20px;
  }

  .discount-element {
    font-size: 22px;
  }

  .text-card {
    font-size: 2rem;
  }

  .text-price-checkout {
    font-size: 20px;
  }

  .text-price-checkout span {
    font-size: 30px;
  }

  .recap-price-checkout {
    font-size: 15px;
  }

  .recap-price-checkout span {
    font-size: 18px;
  }

}

#currency-selector:disabled {
  appearance: none;
  cursor: not-allowed; 
}


</style>