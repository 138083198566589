import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/Login.vue';
import SetPassword from '../views/SetPassword.vue';
import TestView from '../views/test.vue';
import MainLayout from '../layouts/MainLayout.vue';
import AccountsView from '../views/Accounts.vue';
import BillingView from '../views/Billing.vue';
import DeveloperView from '../views/Developer.vue';
import HelpView from '../views/Help.vue';
import AcademyView from '../views/Academy.vue';
import SettingsView from '../views/Settings.vue';
import CheckoutView from '../views/checkout/CheckoutStep1.vue';
import CheckoutViewV2 from '../views/checkout/CheckoutStep2.vue';
import CheckoutViewV3 from '../views/checkout/CheckoutStep3.vue';
import SuccessView from '../views/checkout/SuccessView.vue';
import CancelView from '../views/checkout/CancelView.vue';
import SideBarLayout from '../layouts/SideBarLayout.vue';
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'


const routes = [
  {
    path: '/',
    redirect: '/accounts',
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password - My App',
      description: 'Recover access to your account by resetting your password.',
    },
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'Reset Password - My App',
      description: 'Set a new password to access your account.',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      title: 'Login - My App',
      description: 'Log in to access your account and manage your settings.',
    },
  },
  {
    path: '/SetPassword',
    name: 'SetPassword',
    component: SetPassword,
    props: (route) => ({
      email: route.params.email,
      Nom: decodeURIComponent(route.params.Nom || ''),
      Pr_nom: decodeURIComponent(route.params.Pr_nom || ''),
      Langue: route.params.Langue || 'fr-FR',
    }),
    meta: {
      title: 'Set Password - My App',
      description: 'Set a secure password for your account.',
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: TestView,
    meta: {
      title: 'Test Page - My App',
      description: 'This is a test page for development purposes.',
    },
  },
  {
    path: '/checkout',
    component: SideBarLayout,
    meta: {
      title: 'Checkout - My App',
      description: 'Choose a subscription plan and complete your payment.',
    },
    children: [
      { path: 'v1', name: 'v1', component: CheckoutView, meta: { title: 'Checkout first part', description: 'Checkout using version 1 of the payment flow.' } },
      { path: 'v2', name: 'v2', component: CheckoutViewV2, meta: { title: 'Checkout second part', description: 'Checkout using version 2 of the payment flow.' } },
      { path: 'v3', name: 'v3', component: CheckoutViewV3, meta: { title: 'Checkout third part', description: 'Checkout using version 3 of the payment flow.' } },
      { path: 'v3/success', name: 'SuccessView', component: SuccessView, meta: { title: 'Payment Success - My App', description: 'Your payment was successful. Thank you for your purchase!' } },
      { path: 'v3/cancel', name: 'CancelView', component: CancelView, meta: { title: 'Payment Cancelled - My App', description: 'Your payment was cancelled. Please try again.' } },
    ],
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      { path: 'accounts', name: 'Accounts', component: AccountsView, meta: { title: 'Accounts - My App', description: 'View and manage your accounts.' } },
      { path: 'billing', name: 'Billing', component: BillingView, meta: { title: 'Billing - My App', description: 'Manage your billing information and invoices.' } },
      { path: 'developer', name: 'Developer', component: DeveloperView, meta: { title: 'Developer - My App', description: 'Access developer tools and API documentation.' } },
      { path: 'help', name: 'Help', component: HelpView, meta: { title: 'Help - My App', description: 'Find answers to frequently asked questions and contact support.' } },
      { path: 'academy', name: 'Academy', component: AcademyView, meta: { title: 'Academy - My App', description: 'Learn how to use our platform with tutorials and guides.' } },
      { path: 'settings', name: 'Settings', component: SettingsView, meta: { title: 'Settings - My App', description: 'Update your account and application settings.' } },
    ],
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ name: 'Login' });
  } else {
    if (to.meta.title) {
      document.title = to.meta.title;
    }

    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute('content', to.meta.description || '');
    }

    next();
  }
});

export default router;
