<template>
    <div class="cancel-page flex items-center justify-center min-h-screen bg-red-50">
      <div class="text-center">
        <h1 class="text-4xl font-bold text-red-600">Paiement Annulé</h1>
        <p class="mt-4 text-gray-700">Votre paiement a été annulé. Si vous avez des questions, veuillez nous contacter.</p>
        <router-link
          to="/checkout/v3"
          class="mt-6 inline-block bg-red-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-red-700"
        >
          Réessayer
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CancelView',
  };
  </script>
  
  <style scoped>
  .cancel-page {
    font-family: 'Montserrat', sans-serif;
  }
  </style>
  